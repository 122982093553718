// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import "~@angular/material/theming";
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$tsportweb-primary: mat-palette($mat-indigo);
$tsportweb-accent: mat-palette($mat-pink, A200, A100, A400);

// The warn palette is optional (defaults to red).
$tsportweb-warn: mat-palette($mat-red);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$tsportweb-theme: mat-light-theme(
  (
    color: (
      primary: $tsportweb-primary,
      accent: $tsportweb-accent,
      warn: $tsportweb-warn,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($tsportweb-theme);

/* You can add global styles to this file, and also import other style files */

@import "~bootstrap/dist/css/bootstrap.min.css";
*,
body {
  margin: 0;
  padding: 0;
}
.container {
  width: 650px;
  margin: 0 auto;
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.btn-secondary {
  background-color: #979797;
}

mat-dialog-actions > button {
  margin-left: 10px;
}
button {
  outline: none;
}

// for sidenav
.menu_icon {
  background-color: #d9534f;
  outline: none;
  border: none;
  height: 20px;
  width: 20px;
  color: rgb(255, 255, 255);
  border-radius: 100px;
  width: 40px;
  height: 40px;
  line-height: 54px;
  border: #d43f3a;
}
.menu_icon:hover {
  background-color: #e63c37;
}
.icon {
  text-align: right;
  position: fixed;
  right: 25px;
  z-index: 9;
  top: 5px;
}
.sidenav-items {
  padding-top: 30px;
  padding-bottom: 30px;
}
